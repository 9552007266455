import request from '@/utils/request'

/**
 * 获取登录人信息
 */
export const loginUser = {
    getData(data) {
        return request({
            url: 'v/equipment/heartRate/v3/loginUser',
            method: 'get',
        })
    },
}
