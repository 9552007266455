import request from '@/utils/request'

/**
 * 刷新
 */
export const refresh = {
    refreshData() {
        return request({
            url: 'v/equipment/heartRate/v3/refreshFlag',
            method: 'get',
        })
    },
}
