<template>
  <div class="container">
    <!-- 标题 -->
    <div class="title-bg">
      <span class="title-color">市场端BD工作统计(24小时监测)</span>
      <div class="time-type">
        <div
          :class="['type', index == BdCurrent ? 'type-bg-checked' : 'type-bg']"
          v-for="(item, index) in headerType"
          :key="item.id"
          @click="handleBdSelectTimeItem(item, index)"
        >
          <span
            :class="['text', index == BdCurrent ? 'color-checked' : 'color']"
            >{{ item.label }}</span
          >
        </div>
      </div>
    </div>
    <div class="statistics">
      <!-- 各项数据 -->
      <div class="data">
        <div class="data-box" v-for="item in handleV3BdHeader" :key="item.id">
          <div class="value">{{ item.value }}</div>
          <span class="data-text">{{ item.label }}</span>
        </div>
      </div>
      <!-- 图表 -->
      <Echart :options="handleV3Bd" :height="`180px`"></Echart>
    </div>
  </div>
</template>

<script>
import { bd } from "@/api/ecg/bd.js";
import Echart from "@/common/echart/index.vue";
import { handleString, handleProvince } from "@/utils";
import { mapState, mapActions } from "pinia";
import { getStore } from "@/store/ecg";

export default {
  name: "",
  components: {
    Echart,
  },
  props: {},
  computed: {
    ...mapState(getStore, ["handleV3Bd", "BdCurrent", "handleV3BdHeader"]),
  },
  data() {
    return {
      headerType: [
        { id: 1, label: "全部" },
        { id: 2, label: "今日" },
        { id: 3, label: "近一周" },
        { id: 4, label: "近一月" },
      ],
      headerList: [
        { id: 1, label: "监测总数", value: "0" },
        { id: 2, label: "上传总数", value: "0" },
        { id: 3, label: "审核总数", value: "0" },
        { id: 4, label: "BD工作总数", value: "0" },
      ],
    };
  },
  mounted() {
  },
  methods: {
    ...mapActions(getStore, ["handleBdSelectTimeItem"]),
  },
};
</script>

<style scoped lang="scss">
.text {
  font-size: 12px;
  font-weight: 700;
}

.color {
  background: linear-gradient(180deg, #eeeeee 0%, #86e1ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.color-checked {
  background: linear-gradient(180deg, #eeeeee 0%, #e7c618 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.value {
  // font-family: 黑体 !important;
  // font-family: HeadLineA Regular !important;
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  background-image: linear-gradient(to top, #01e0ff, #0171ff);
  -webkit-background-clip: text;
  color: transparent;
}

.container {
  .title-bg {
    height: 28px;
    background: url("~@/assets/chart_right_bg.png");
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 37px;
    .title-color {
      font-weight: normal;
      color: #ffffff;
      background: linear-gradient(180deg, #eeeeee 0%, #86e1ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 14px;
      font-weight: 700;
    }
  }

  .time-type {
    display: flex;
    .type {
      width: 81px;
      height: 22px;
      text-align: center;
      cursor: pointer;
      margin-right: 6px;
    }
    .type-bg {
      background-image: url("~@/assets/chart_space.png");
      background-size: 81px 22px;
    }
  }

  .type-bg-checked {
    background-image: url("~@/assets/chart_space_current.png");
    background-size: 81px 22px;
  }

  .statistics {
    padding: 10px 15px;
  }

  // 各项数据
  .data {
    display: flex;
    justify-content: space-between;
    &-box {
      width: 142px;
      height: 51px;
      background: rgba(0, 107, 255, 0.3);
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
    }
    &-text {
      font-size: 9px;
    }
    &-box {
      width: 142px;
      height: 51px;
      background: url("~@/assets/header_space_bd.png");
      background-size: cover;
    }
  }
}
</style>
