<template>
  <div class="container">
    <!-- 标题 -->
    <div class="title-bg">
      <span class="title-color">库存Top5</span>
    </div>
    <!-- 图表 -->
    <Echart :options="handleStock" :height="`240px`"></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart/index.vue";
import { topFive } from "@/api/ecg/topFive";
import { handleString, handleProvince } from "@/utils";
import { mapState, mapActions } from "pinia";
import { getStore } from "@/store/ecg";

export default {
  name: "",
  components: {
    Echart,
  },
  props: {},
  computed: {
    ...mapState(getStore, ["handleStock"]),
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    getData() {
      let hospitalId = sessionStorage.getItem("currentHospitalId");
      let province = handleProvince(
        JSON.parse(sessionStorage.getItem("province")) ?? []
      );
      topFive.getData({ hospitalId, ...province }).then((res) => {
        const name = {
          手机: "cellPhone",
          "80口充电桩": "eightyChargingPile",
          电极片: "electrodeSlice",
          好络维心电仪: "heartEquipment",
          物联网卡: "internetOfThingsCard",
          "10口充电桩": "thenChargingPile",
        };
        this.cdata.yAxis.data = res.data.xData
          .reverse()
          .map((item) => handleString(item));
        this.cdata.series = this.cdata.series.map((item) => {
          item.data = res.data.serieData[name[item.name]].reverse() ?? [];
          return item;
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  .title-bg {
    height: 28px;
    background: url("~@/assets/chart_right_bg.png");
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 37px;
    .title-color {
      font-weight: normal;
      color: #ffffff;
      background: linear-gradient(180deg, #eeeeee 0%, #86e1ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 14px;
      font-weight: 700;
    }
  }
}
</style>
