<template>
  <div class="nodata">
    <div class="header">
      <span class="top_title fw">实时监测</span>
    </div>
    <dv-scroll-board
      :config="handleV3RealTime"
      style="height: 250px"
      @click="handleGotoParticulars"
    />
    <!-- <div class="nodata-text" v-show="!config.data.length">暂无数据</div> -->
  </div>
</template>

<script>
import { supervision } from "@/api/ecg/supervision.js";
import { mapState, mapActions } from "pinia";
import { getStore } from "@/store/ecg";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(getStore, ["handleV3RealTime", "realTimeData"]),
  },
  mounted() {
    // this.getData();
  },
  methods: {
    // getData() {
    //   let hospitalId = sessionStorage.getItem("currentHospitalId");
    //   let province = handleProvince(
    //     JSON.parse(sessionStorage.getItem("province")) ?? []
    //   );
    //   supervision.getData({ hospitalId, ...province }).then((res) => {
    //     this.swiperList = res.data;
    //     const swiperList = res.data.map((item) => {
    //       return (item = [
    //         handleString(item.hospitalName),
    //         handleString(item.patientName),
    //         item.age,
    //         item.genderStr,
    //         item.ecgResult,
    //         item.inspectionTimeStr,
    //       ]);
    //     });
    //     this.config.data = swiperList;
    //     this.config = { ...this.config };
    //   });
    // },
    handleGotoParticulars(val) {
      this.$router.push(
        `/particulars?userId=${this.realTimeData[val.rowIndex].patientUserId}`
      );
    },
  },
};
</script>
<style>
.header-item {
  font-size: 12px;
}
.row-item .ceil {
  font-size: 10px;
}
</style>
<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 28px;
  background-image: url("~@/assets/chart_top_center_bg.png");
  background-size: 100% 100%;
  background-position: center center;
}
.top_title {
  margin: 0 auto;
  font-size: 14px;
  background: linear-gradient(180deg, #eeeeee 0%, #86e1ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
