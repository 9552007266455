<template>
  <div>
    <div class="header">
      <!-- 近30天 -->
      <div
        @click="handleSelectItem('nearly_day')"
        class="nearly_day fw"
        :class="[current === 1 ? 'topBg_current' : 'topBg']"
      >
        <span :class="[current === 1 ? 'current_color' : 'color']">近30天</span>
      </div>
      <!-- 近一年 -->
      <div
        @click="handleSelectItem('nearly_year')"
        class="nearly_year fw"
        :class="[current === 2 ? 'topBg_current' : 'topBg']"
      >
        <span :class="[current === 2 ? 'current_color' : 'color']"
          >近一年
        </span>
      </div>
      <span class="top_title fw">监测数据</span>
    </div>
    <!-- 图表 -->
    <Echart :options="handleV3Monitor" :height="`280px`"></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart/index.vue";
import { monitoringData } from "@/api/ecg/monitoringData.js";
import { handleProvince } from "@/utils";

import { mapState, mapActions } from "pinia";
import { getStore } from "@/store/ecg";
export default {
  name: "",
  components: { Echart },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(getStore, ["handleV3Monitor", "current"]),
  },
  mounted() {
  },
  methods: {
    handleProvince,
    ...mapActions(getStore, ["handletype", "handleSelectItem"]),
  },
};
</script>

<style scoped lang="scss">
.header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 28px;
  background-image: url("~@/assets/chart_top_bg.png");
  background-size: 100% 100%;
  background-position: center center;
}
.nearly_day,
.nearly_year {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 81px;
  height: 22px;
  margin-left: 10px;
  font-family: FZLTTHK--GBK1-0, FZLTTHK--GBK1;
  font-size: 12px;
  cursor: pointer;
}
.topBg {
  background-image: url("~@/assets/chart_space.png");
  background-size: 100% 100%;
  background-position: center center;
}
.topBg_current {
  background-image: url("~@/assets/chart_space_current.png");
  background-size: 100% 100%;
  background-position: center center;
}
.top_title {
  margin-left: auto;
  margin-right: 40px;
  font-size: 14px;
  background: linear-gradient(180deg, #eeeeee 0%, #86e1ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.current_color {
  background: linear-gradient(180deg, #eeeeee 0%, #e7c618 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.color {
  background: linear-gradient(180deg, #eeeeee 0%, #84e2ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
