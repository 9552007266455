import request from '@/utils/request'

/**
 * TOP5
 */
export const topFive = {
    getData(data) {
        return request({
            url: 'v/equipment/heartRate/v3/stock',
            method: 'get',
            params: data
        })
    },
}
