<template>
  <div>
    <div class="header">
      <span class="top_title fw">近2月待上传率、待归还率、待审核率统计</span>
    </div>
    <!-- 图表 -->
    <Echart :options="handleV3UpLoadRate" :height="`280px`"></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart/index.vue";
import { lastTwoMonths } from "@/api/ecg/lastTwoMonths";
// import { handleProvince } from "@/utils";
import { mapState, mapActions } from "pinia";
import { getStore } from "@/store/ecg";
export default {
  name: "",
  components: { Echart },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapState(getStore, ["handleV3UpLoadRate"]),
  },
  mounted() {},
  methods: {
    // getData() {
    //   let hospitalId = sessionStorage.getItem("currentHospitalId");
    //   let province = handleProvince(
    //     JSON.parse(sessionStorage.getItem("province")) ?? []
    //   );
    //   let endTime = new Date(new Date().toLocaleDateString()).getTime();
    //   let startTime = endTime - 60 * 3600 * 24 * 1000; // 最近60天
    //   lastTwoMonths
    //     .getData({ startTime, endTime, type: "MONTH", hospitalId, ...province })
    //     .then((res) => {
    //       const xAxisData = res.data.xData.reverse();
    //       const serieData = res.data.serieData;
    //       this.cdata.xAxis.data = xAxisData;
    //       this.cdata.series[0].data = serieData.waitUploadRate.reverse();
    //       this.cdata.series[1].data = serieData.waitReturnRate.reverse();
    //       this.cdata.series[2].data = serieData.waitAuditedRate.reverse();
    //       this.cdata.series[3].data = serieData.count.reverse();
    //       this.cdata.series[4].data = serieData.waitUploadCount.reverse();
    //       this.cdata.series[5].data = serieData.waitReturnCount.reverse();
    //       this.cdata.series[6].data = serieData.waitAuditedCount.reverse();
    //     });
    // },
  },
};
</script>

<style scoped lang="scss">
.header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 28px;
  background-image: url("~@/assets/chart_top_bg.png");
  background-size: 100% 100%;
  background-position: center center;
}
.top_title {
  margin-left: auto;
  margin-right: 40px;
  font-size: 14px;
  background: linear-gradient(180deg, #eeeeee 0%, #86e1ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
