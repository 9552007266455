// 聚合接口
import request from '@/utils/request'

export const aggregate = {
    getV3HeartRateAggregate(data) {
        return request({
            url: 'v/equipment/heartRate/v3/aggregate',
            method: 'get',
            params: data
        })
    },
}
