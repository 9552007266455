<template>
  <div class="nodata">
    <div class="header">
      <span class="top_title fw">实时建档</span>
    </div>
    <dv-scroll-board
      :config="handleArchive"
      @click="handleGotoParticulars"
      style="height: 250px"
    />
    <!-- <div class="nodata-text" v-show="!config.data.length">暂无数据</div> -->
  </div>
</template>

<script>
import { filing } from "@/api/ecg/filing.js";
import { handleString, handleProvince } from "@/utils";
import { mapState, mapActions } from "pinia";
import { getStore } from "@/store/ecg";

export default {
  name: "",
  components: {},
  props: {},
  computed: {
    ...mapState(getStore, ["handleArchive", "archive"]),
  },
  data() {
    return {
    };
  },
  mounted() {},
  methods: {
    handleGotoParticulars(val) {
      this.$router.push(
        `/particulars?userId=${this.archive[val.rowIndex].id}`
      );
    },
  },
};
</script>
<style>
.header-item {
  font-size: 12px;
  font-weight: 700;
  background: linear-gradient(180deg, #eeeeee 0%, #86e1ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.row-item .ceil {
  font-size: 12px;
  /* background: linear-gradient(180deg, #eeeeee 0%, #86e1ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}
</style>
<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 28px;
  background-image: url("~@/assets/chart_top_center_bg.png");
  background-size: 100% 100%;
  background-position: center center;
}
.top_title {
  margin: 0 auto;
  font-size: 14px;
  background: linear-gradient(180deg, #eeeeee 0%, #86e1ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
