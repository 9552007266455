<template>
  <div class="nodata">
    <div class="header">
      <span class="top_title fw">站点统计</span>
    </div>
    <dv-scroll-board :config="handleV3Service" style="height: 250px" />
    <!-- <div class="nodata-text" v-show="!config.data.length">暂无数据</div> -->
  </div>
</template>

<script>
import { siteStatistics } from "@/api/ecg/siteStatistics.js";
import { handleString, handleProvince } from "@/utils";
import { mapState, mapActions } from "pinia";
import { getStore } from "@/store/ecg";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      handleString,
      config: {
        header: [
          "医院名称",
          "已监测总人数",
          "已监测总次数",
          "待上传",
          "已审核",
          "待审核",
        ],
        headerBGC: "",
        oddRowBGC: "",
        rowNum: "4",
        evenRowBGC: "",
        waitTime: 3000,
        columnWidth: ["145", "145", "145", "80", "80", "80"],
        align: [
          "center",
          "center",
          "center",
          "center",
          "center",
          "center",
          "center",
          "center",
        ],
        data: [],
      },
    };
  },
  computed: {
    ...mapState(getStore, ["handleV3Service"]),
  },
  mounted() {},
  methods: {},
};
</script>
<style>
.header-item {
  font-size: 12px;
}
.row-item .ceil {
  font-size: 10px;
}
</style>
<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 28px;
  background-image: url("~@/assets/chart_top_center_bg.png");
  background-size: 100% 100%;
  background-position: center center;
}
.top_title {
  margin: 0 auto;
  font-size: 14px;
  background: linear-gradient(180deg, #eeeeee 0%, #86e1ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
