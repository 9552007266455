<template>
  <div class="container nodata">
    <!-- 标题 -->
    <div class="title-bg">
      <span class="title-color">绩效KPI</span>
    </div>
    <dv-scroll-board
      :config="handleV3Kpi"
      @click="handleGotoParticulars"
      style="height: 250px"
    />
    <!-- <div class="nodata-text" v-show="!config.data.length">暂无数据</div> -->
  </div>
</template>

<script>
import { kpi } from "@/api/ecg/kpi.js";
import { handleString, handleProvince } from "@/utils";
import { mapState, mapActions } from "pinia";
import { getStore } from "@/store/ecg";

export default {
  name: "",
  computed: {
    ...mapState(getStore, ["handleV3Kpi", "kpiData"]),
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    handleGotoParticulars(val) {
      this.$router.push(
        `/particulars?userId=${this.kpiData[val.rowIndex].patientUserId}`
      );
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  .title-bg {
    height: 28px;
    background: url("~@/assets/chart_right_bg.png");
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 37px;
    .title-color {
      font-weight: normal;
      color: #ffffff;
      background: linear-gradient(180deg, #eeeeee 0%, #86e1ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 14px;
      font-weight: 700;
    }
  }
}
</style>
