import request from '@/utils/request'

/**
 * 医院信息
 */
export const hospital = {
    getData(data) {
        return request({
            url: 'v/equipment/glucose/v2/hospital/search',
            method: 'get',
            params: data
        })
    },
    getInfo(id) {
        return request({
            url: `v/equipment/glucose/v2/hospital/info/${id}`,
            method: 'get',
        })
    },
}
