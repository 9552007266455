<template>
  <div class="nodata">
    <div class="header">
      <!-- 近30天 -->
      <div
        @click="handleSelectItem('device')"
        class="nearly_day fw"
        :class="[current === 1 ? 'topBg_current' : 'topBg']"
      >
        <span :class="[current === 1 ? 'current_color' : 'color']"
          >设备异常</span
        >
      </div>
      <!-- 近一年 -->
      <div
        @click="handleSelectItem('apply')"
        class="nearly_year fw"
        :class="[current === 2 ? 'topBg_current' : 'topBg']"
      >
        <span :class="[current === 2 ? 'current_color' : 'color']"
          >应用异常</span
        >
      </div>
      <span class="top_title fw">异常预警</span>
    </div>
    <dv-scroll-board
      :config="current === 1 ? handleDevice : handleApply"
      @click="handleGotoParticulars"
      style="height: 250px"
    />
    <!-- <div class="nodata-text" v-show="current === 1 ? !config.data.length : !config2.data.length">暂无数据</div> -->
  </div>
</template>

<script>
import { abnormal } from "@/api/ecg/abnormal.js";
import { handleString, handleProvince } from "@/utils";

import { mapState, mapActions } from "pinia";
import { getStore } from "@/store/ecg";
export default {
  name: "",
  components: {},
  computed: {
    ...mapState(getStore, ["device", "apply", "handleDevice", "handleApply"]),
  },
  props: {},
  data() {
    return {
      current: 1,
      AbnormalCurrent: 1,
      swiperList: [],
    };
  },
  mounted() {},
  methods: {
    handleSelectItem(val) {
      if (val === "device") {
        this.current = 1;
      } else if (val === "apply") {
        this.current = 2;
      }
    },
    handleGotoParticulars(val) {
      if(this.current == 1){
        this.swiperList = this.device
      }else{
        this.swiperList = this.apply
      }
      if (this.swiperList[val.rowIndex].patientUserId) {
        this.$router.push(
          `/particulars?userId=${this.swiperList[val.rowIndex].patientUserId}`
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 28px;
  background-image: url("~@/assets/chart_top_bg.png");
  background-size: 100% 100%;
  background-position: center center;
}
.nearly_day,
.nearly_year {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 81px;
  height: 22px;
  margin-left: 10px;
  font-family: FZLTTHK--GBK1-0, FZLTTHK--GBK1;
  font-size: 12px;
  cursor: pointer;
}
.topBg {
  background-image: url("~@/assets/chart_space.png");
  background-size: 100% 100%;
  background-position: center center;
}
.topBg_current {
  background-image: url("~@/assets/chart_space_current.png");
  background-size: 100% 100%;
  background-position: center center;
}
.top_title {
  margin-left: auto;
  margin-right: 40px;
  font-size: 14px;
  background: linear-gradient(180deg, #eeeeee 0%, #86e1ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.current_color {
  background: linear-gradient(180deg, #eeeeee 0%, #e7c618 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.color {
  background: linear-gradient(180deg, #eeeeee 0%, #84e2ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
